import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "The Teehan+Lax Partners Are Joining Facebook",
  "date": "2015-01-16T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I have mixed feelings about `}<a parentName="p" {...{
        "href": "http://www.teehanlax.com/"
      }}>{`the announcement that the Teehan+Lax partners are joining Facebook and shutting down the agency`}</a>{`.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`I’m happy for them and wish them all the best at Facebook. They have an amazing track record and I hope they can use that experience to make Facebook a better product for the millions of people who use it.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`I’m sad for the `}<a parentName="p" {...{
            "href": "https://mobile.twitter.com/derekkinsman/status/556098484953432068"
          }}>{`40 people`}</a>{` that are all of a sudden left without a job. They’re super talented, so I’m very confident that they won’t have trouble finding another job. (If any one of them is reading this, we’re hiring in Toronto, Montreal and Ottawa, so `}<a parentName="p" {...{
            "href": "mailto:kevin@kevinclark.ca"
          }}>{`get in touch`}</a>{`.)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Design is becoming more and more an integral part of many businesses, so companies prefer having in-house teams over working with agencies. It’s only going to become harder and harder for design agencies to stay independent.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We don’t know everything. It’s easy to judge without knowing all the facts. Running a company is extremely difficult. The Teehan+Lax partners are good guys, let’s give them the benefit of the doubt.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      